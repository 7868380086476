import { Fragment, useState, useEffect } from "react";
import { Layout, App } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableDaemonList } from "./components";
import { useFetchGetDaemonScope } from "hooks";
import { langError } from "tools";
import usePageTitle from "hooks/usePageTitle";

const PageOperationDaemon = () => {
	const { notification } = App.useApp();
	const [filterData, setFilterData] = useState({});
	const [actionOptions, setActionOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	usePageTitle("后台脚本 - IT管理后台");

	const [FetchGetDaemonScope] = useFetchGetDaemonScope();

	useEffect(() => {
		setLoading(true);
		FetchGetDaemonScope({}, {
			success: (data) => {
				let newArr = []
				if (data.length > 0) {
					newArr = data.map(item => {
						return {
							value: item.key,
							label: item.value
						}
					})
				}
				setFilterData({scope: data[0].key});
				setActionOptions(newArr);
				setLoading(false);
			},
			error: (error) => {
				setLoading(false);
				notification.error({ message: langError(error.code) });
			},
		});
	}, []);

	const selectOptions = [
		{
			key: "scope",
			placeholder: "请选择Scope",
			options: actionOptions,
		}
	];



	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["operation/daemon"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
								loading={loading}
								initialValues={{scope: filterData.scope}}
							/>
							<TableDaemonList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageOperationDaemon;
