import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, App, Select, InputNumber } from 'antd';
import { consoleLog, langError } from 'tools';
import { useFetchRiskifyPackageTarget, useFetchRiskifyRedeemcodeAdd } from "hooks";

export const ModalCreate = ({ open, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果
    const [loadingOption, setLoadingOption] = useState(false);  // save按钮的loading效果
    const [optionList, setOptionList] = useState([]);  // save按钮的loading效果

    const [fetchSave] = useFetchRiskifyRedeemcodeAdd();
    const [fetchPackageTarget] = useFetchRiskifyPackageTarget();

    useEffect(()=>{
        setLoadingOption(true);
        fetchPackageTarget({}, {
            success: (data) => {
                setLoadingOption(false);
                setOptionList(data);
            },
            error: (error) => {
                setLoadingOption(false);
                notification.error({'message':langError(error.code)});
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                category:"appsumo",
                type:2,
                target:values.target,
                limit:values.limit,
            }, {
                success: (data) => {
                    setLoadingSave(false);
                    onCancel();
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    notification.success({"message":"保存成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }
    return (
        <Modal
            destroyOnClose
            open={open}
            title={"创建Redeemcode"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="target"
                    label={"设置Package Target"}
                    style={{marginBottom:10}}
                >
                   <Select loading={loadingOption} placeholder="请选择Package Target">
                        {
                            optionList.length>0?
                                optionList.map(item =>(
                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                )):null
                        }
                   </Select>
                </Form.Item>
                <Form.Item
                    name="limit"
                    label={"Limit"}
                    style={{marginBottom:10}}
                >
                   <InputNumber style={{width:"100%"}} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
