import React, { useEffect, useState } from "react";
import { Select } from "antd";

export const SelectFilter = ({ data, loading, initialValues, onHandleChangeFilter }) => {

    const [selectedValue, setSelectedValue] = useState(null);

	useEffect(()=>{
		if(initialValues){
            if(initialValues[data.key] && !selectedValue){
                setSelectedValue(initialValues[data.key]);
            }
		}
	},[initialValues, selectedValue]);

    const handleSelectOptions = (value, key) => {
		setSelectedValue(value);
		if(onHandleChangeFilter){
			onHandleChangeFilter(key, value);
		}
	};

	return (
		<Select
            key={data.key}
            style={{ width: "100%" }}
            placeholder={data.placeholder}
            onChange={(e) => handleSelectOptions(e, data.key)}
            loading={loading}
            value={selectedValue}
        >
            {data.options.map((option, index) => (
                <Select.Option value={option.value} key={index}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
	);
};
