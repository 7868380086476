import { Fragment, useEffect, useState } from "react";
import { Layout, App } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TableList } from "./components";
import { useFetchGetMailboxInboxList } from "hooks";
import usePageTitle from "hooks/usePageTitle";
import { langError } from "tools";

const PageMailboxInbox = () => {
	const { notification } = App.useApp();
	const [filterData, setFilterData] = useState({});
	const [loading, setLoading] = useState(false);
	const [emailList, setEmailList] = useState([]);

	usePageTitle("收件箱 - Mailbox管理后台");

	const [fetchGetMailboxInboxList] = useFetchGetMailboxInboxList();

	useEffect(() => {
		setLoading(true);
		fetchGetMailboxInboxList({}, {
			success: (data) => {
				let newArr = [];
				if(data.length>0){
					data.map(item =>{
						newArr.push({
							value:item.key,
							label:item.name
						})
					})
				}
				setFilterData({email: data[0].key});
				setEmailList(newArr);
				setLoading(false);
			},
			error: (error) => {
				setLoading(false);
				notification.error({ message: langError(error.code) });
			},
		});
	}, []);

	const inputOptions = [
		{ key: "name", placeholder: "搜索用户名" },
		{ key: "email", placeholder: "搜索邮箱" },
		{ key: "source", placeholder: "搜索来源" },
		{ key: "partner_key", placeholder: "搜索Partner Key" },
	];

	const selectOptions = [
		{
			key: "type",
			placeholder: "选择分类",
			options: [
				{ value: "", label: "选择分类" },
				{ value: "reply", label: "回复" },
				{ value: "auto", label: "自动回复" },
			],
		},
		{
			key: "email",
			placeholder: "选择发件人邮箱",
			options:emailList
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["mailbox/inbox"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								loading={loading}
								selectOptions={selectOptions}
								datePickerKey={"time"}
								inputOptions={inputOptions}
								onHandleChangeFilter={handleChangeFilter}
								initialValues={{email: filterData.email}}
							/>
							<TableList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMailboxInbox;
