import { Fragment, useEffect, useState } from "react";
import { Layout, App } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TableTaskList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageEDMTask = () => {
	const [filterData, setFilterData] = useState({});
	const [loading, setLoading] = useState(false);

	usePageTitle("群发任务列表 - EDM管理后台");

	const inputOptions = [
		{ key: "name", placeholder: "任务名称" },
	];

	const selectOptions = [
		{
			key: "status",
			placeholder: "选择状态",
			options: [
				{ value: "", label: "选择状态" },
				{ value: 1, label: "准备中" },
				{ value: 2, label: "处理中" },
				{ value: 3, label: "投递中" },
				{ value: 8, label: "成功" },
				{ value: 9, label: "失败" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["edm/task"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								loading={loading}
								selectOptions={selectOptions}
								// datePickerKey={"time"}
								inputOptions={inputOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableTaskList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageEDMTask;
